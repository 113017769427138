var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header__container"},[_c('div',{staticClass:"container"},[(_vm.isNeedHeader)?_c('div',{staticClass:"header__wrapper"},[_c('div',{staticClass:"header__title"},[(
            (_vm.headers[_vm.active] && _vm.headers[_vm.active].notmain) ||
            _vm.isInSpecialType ||
            _vm.$route.meta.isNeedBackButton ||
            !_vm.isLogged
          )?_c('div',{staticClass:"header_back_btn icon__wrapper",on:{"click":_vm.routeBack}},[_c('a-icon',{attrs:{"type":"left"}})],1):_vm._e(),_vm._v(" "+_vm._s(_vm.headerTitle)+" ")]),_c('div',{staticClass:"header__right-side"},[(_vm.headers[_vm.active] && _vm.isLogged)?_c('transition-group',{staticClass:"header__buttons",attrs:{"name":"header__item-anim","tag":"div"}},_vm._l((_vm.headers[_vm.active].buttons),function(button){return _c('div',{key:button.icon,staticClass:"header__button"},[(button.onClickFuncion && button.type == undefined)?_c('div',{staticClass:"icon__wrapper",class:[
                { active__btn: _vm.getState(button.name) },
                button.additionalClass,
              ],on:{"click":button.onClickFuncion}},[_c('a-icon',{staticClass:"header__icon",attrs:{"spin":button.isSpin,"type":button.icon}})],1):(button.onClickFuncion && button.type != undefined)?_c('div',{staticClass:"icon__wrapper btn--no-image",class:[
                { active__btn: _vm.getState(button.name) },
                button.additionalClass,
              ],on:{"click":button.onClickFuncion}},[_c('div',{staticClass:"header__btn--no-image"},[_vm._v(_vm._s(button.name))])]):(button.icon == 'search')?_c('div',{staticClass:"icon__wrapper",class:[button.additionalClass]},[(!button.popover)?_c('a-icon',{staticClass:"header__icon",attrs:{"type":button.icon}}):_c('a-popover',{attrs:{"placement":"bottomRight","arrow-point-at-center":""}},[_c('template',{slot:"content"},[_c('div',[_c('a-input-search',{attrs:{"placeholder":"title/status/IP","enter-button":"","value":_vm.searchString},on:{"input":(e) => _vm.$store.commit('nocloud/vms/setSearch', e.target.value),"search":(text) => _vm.$store.commit('nocloud/vms/setSearch', text),"keydown":_vm.updateSearch}},[_c('div',{staticStyle:{"cursor":"pointer"},attrs:{"slot":"suffix"},on:{"click":function($event){return _vm.$store.commit('nocloud/vms/setSearch', '')}},slot:"suffix"},[_c('a-icon',{staticStyle:{"color":"rgba(0, 0, 0, 0.45)"},attrs:{"type":"close"}})],1)])],1)]),_c('template',{slot:"title"},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("search"))))])]),_c('a-icon',{staticClass:"header__icon",attrs:{"type":button.icon}})],2)],1):_c('div',{staticClass:"icon__wrapper",class:[
                { active__btn: _vm.getState(button.name) },
                button.additionalClass,
              ]},[(!button.popover)?_c('a-icon',{staticClass:"header__icon",attrs:{"type":button.icon}}):_c('a-popover',{attrs:{"placement":"bottomRight","arrow-point-at-center":""}},[_c('template',{slot:"content"},[_c('div',[_c('a-checkbox-group',{attrs:{"options":_vm.plainOptions},on:{"change":_vm.onChange},model:{value:(_vm.checkedList),callback:function ($$v) {_vm.checkedList=$$v},expression:"checkedList"}})],1)]),_c('template',{slot:"title"},[_c('span',[_vm._v(_vm._s(_vm._f("capitalize")(_vm.$t("filter"))))])]),_c('a-icon',{staticClass:"header__icon",attrs:{"type":button.icon}})],2)],1)])}),0):_vm._e(),_c('transition',{attrs:{"name":"header__item-anim"}},[(_vm.isNeedBalance && _vm.isLogged)?_c('div',{staticClass:"header__balance"},[_c('balance')],1):_vm._e()]),(!_vm.isLogged)?_c('div',{staticClass:"header__links"},[_c('router-link',{attrs:{"to":{ name: 'login' }}},[_vm._v(_vm._s(_vm.$t("login")))]),_c('router-link',{attrs:{"to":{ name: 'register' }}},[_vm._v(_vm._s(_vm.$t("unregistered.sign up")))])],1):_vm._e()],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }